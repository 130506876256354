'use client'

import { Spacer } from '@vinted/web-ui'

import { useSession } from '@marketplace-web/shared/session'
import { getTestId } from '@marketplace-web/shared/ui-helpers'
import { CurrencyAmountModel, ProductItemUserModel } from 'types/models'

import ServiceFeeIncludedTitle from '../ServiceFeeIncludedTitle'

type Props = {
  serviceFee?: CurrencyAmountModel | null
  id: number
  price: CurrencyAmountModel
  totalItemPrice?: CurrencyAmountModel
  user: ProductItemUserModel
  priceWithDiscount?: CurrencyAmountModel | null
  testId: string
}

const ItemPriceBreakdown = ({
  serviceFee,
  id,
  price,
  totalItemPrice,
  user,
  priceWithDiscount,
  testId,
}: Props) => {
  const { user: currentUser } = useSession()

  const isViewingSelf = currentUser?.id === user.id
  const showPriceBreakdownChanges = !!serviceFee && !isViewingSelf

  if (!showPriceBreakdownChanges) return null

  return (
    <div data-testid={getTestId(testId, 'breakdown')}>
      <ServiceFeeIncludedTitle
        itemId={id}
        itemPrice={priceWithDiscount || price}
        serviceFee={serviceFee}
        totalItemPrice={totalItemPrice}
        isSellerBusiness={!!user.isBusiness}
        shouldTrimTitle
        shouldMinimiseTitle
      />
      <Spacer size={Spacer.Size.Small} />
    </div>
  )
}

export default ItemPriceBreakdown
